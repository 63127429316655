<template>
  <v-row align="center">
    <v-col cols="12" md="6"
      ><v-select
        v-model="firstSelectedId"
        v-bind="$attrs"
        :items="firstItems"
        :label="first.label"
        :item-text="first.text"
        :loading="loading"
        clearable
        full-width
        item-value="id"
      />
    </v-col>

    <v-col cols="12" md="6">
      <v-select
        v-model="secondSelectedId"
        v-bind="$attrs"
        :items="secondItems"
        :label="second.label"
        :item-text="second.text"
        :loading="loading"
        clearable
        full-width
        item-value="id"
    /></v-col>
  </v-row>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },

    url: {
      type: String,
      required: true
    },

    first: {
      type: Object, // { label: string, key: string, text: string }
      required: true
    },

    second: {
      type: Object, // { label: string, key: string, text: string }
      required: true
    }
  },

  data() {
    return {
      internalChanged: false,

      loading: false,

      items: [],

      firstSelectedId: undefined,
      secondSelectedId: undefined
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    firstItems() {
      const items = isNaN(this.secondSelectedId)
        ? this.items
        : this.items.filter(
            i => i[this.second.key].id === this.secondSelectedId
          );

      return items.map(i => i[this.first.key]);
    },

    secondItems() {
      const items = isNaN(this.firstSelectedId)
        ? this.items
        : this.items.filter(i => i[this.first.key].id === this.firstSelectedId);

      return items.map(i => i[this.second.key]);
    }
  },

  watch: {
    firstSelectedId(val) {
      if (!val || !this.secondSelectedId) return (this.selected = undefined);

      this.updateSelected();
    },

    secondSelectedId(val) {
      if (!val || !this.firstSelectedId) return (this.selected = undefined);

      this.updateSelected();
    },

    value(val) {
      if (this.internalChanged) {
        return (this.internalChanged = false);
      }

      this.initSelected();
    }
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.fetchData();
    },

    async fetchData() {
      try {
        this.loading = true;

        const res = await request({
          url: this.url
        });

        this.items = res.data;

        this.initSelected();
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      } finally {
        this.loading = false;
      }
    },

    initSelected() {
      if (!this.value) {
        this.firstSelectedId = undefined;
        this.secondSelectedId = undefined;
      } else {
        const selectedItem = this.items.find(i => i.id === this.value);

        if (!selectedItem) return;

        this.firstSelectedId = selectedItem[this.first.key].id;
        this.secondSelectedId = selectedItem[this.second.key].id;
      }
    },

    updateSelected() {
      const selectedItem = this.items.find(
        i =>
          i[this.first.key].id === this.firstSelectedId &&
          i[this.second.key].id === this.secondSelectedId
      );

      if (!selectedItem)
        return console.error(
          "Something went wrong. This should not be happened"
        );

      this.selected = selectedItem.id;

      this.internalChanged = true;
    }
  }
};
</script>
