<template>
  <v-container style="height: calc(100vh - 60px); overflow: auto" fluid>
    <v-card :loading="loading" class="pa-2">
      <!-- Title -->
      <v-card-title>Giám sát thi</v-card-title>

      <!-- Actions -->
      <v-card-text class="py-0">
        <v-row align="center">
          <v-col cols="12" md="5">
            <ServerPivotSelect
              v-model="examinationSubjectId"
              url="/examinations/monitoring/meta"
              :first="{ label: 'Kíp thi', key: 'examination', text: 'name' }"
              :second="{ label: 'Học phần', key: 'subject', text: 'fullName' }"
              dense
              hide-details
              class="flex"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="search"
              placeholder="Tìm theo Tên, Mã hoặc Email sinh viên"
              dense
              clearable
              hide-details
              full-width
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-select
              v-model="selectedClassId"
              :items="classIds"
              dense
              clearable
              hide-details
              full-width
              label="Lớp thi"
              @change="onFilterChanged"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-select
              v-model="selectedStudyGroupName"
              :items="studyGroupNames"
              dense
              clearable
              hide-details
              full-width
              label="Nhóm học"
              @change="onFilterChanged"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Pagination -->
      <v-card-text class="py-0">
        <v-row align="center">
          <v-col cols="12" class="py-0">
            <v-select
              hide-details
              style="width:200px"
              :items="[
                { id: 'form_statuses.current_question', text: 'Số câu đã làm' },
                { id: 'forms.order', text: 'Số thứ tự' },
                { id: 'forms.student_code', text: 'Mã số sinh viên' }
              ]"
              v-model="selectedOrderBy"
              label="Sắp xếp theo"
              item-value="id"
              clearable
              @change="fetchData"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-row align="center">
              <div
                class="mx-2 caption"
                style="line-height: 30px; padding-top: 5px;"
              >
                Tổng: {{ formTotal }}
              </div>
              <div
                class="mx-2 caption"
                style="line-height: 30px; padding-top: 5px;"
              >
                Số lượng / trang:
              </div>

              <div style="width: 64px">
                <v-select
                  v-model="pageSize"
                  :items="[10, 20, 50, 100]"
                  dense
                  hide-details
                  single-line
                  @change="onFilterChanged"
                />
              </div>
            </v-row>
          </v-col>

          <!-- Pages -->
          <v-col cols="12" md="6">
            <v-pagination
              v-model="page"
              :length="paginationLength"
              :total-visible="6"
              circle
              class="mr-2"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-btn
              small
              block
              color="secondary"
              :loading="loading"
              @click="fetchData"
              >Làm mới Danh sách</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Items -->
      <v-card-text class="pt-0">
        <v-row>
          <v-col v-for="(form, i) of forms" :key="i" cols="12" md="2">
            <v-card @click="showFormDetail(form)">
              <v-img
                :src="loading ? imageLoading : `/api/forms/${form.id}/image?u=${randomNumber}`"
                :lazy-src="imageLoading"
              />

              <div v-if="form.imageSource === 'cam'" class="item-tag">
                Camera
              </div>
              <div v-else-if="form.imageSource === 'scr'" class="item-tag">
                Chụp màn hình
              </div>

              <v-card-title class="mb-3">
                {{ form.studentName }}
              </v-card-title>

              <v-card-subtitle class="mb-0">
                {{ form.studentCode }}
              </v-card-subtitle>

              <v-card-text class="d-flex align-center justify-space-between">
                <div title="Số câu đã làm" class="mr-4">
                  <v-icon class="mr-1">mdi-progress-question</v-icon>
                  <span>{{ form.status.currentQuestion }}</span>
                </div>

                <div title="Số đề" class="mr-4">
                  <v-icon class="mr-1">mdi-book</v-icon>
                  <span>{{ form.exam.code }}</span>
                </div>

                <div title="Số thứ tự" class="mr-4">
                  <v-icon class="mr-1">mdi-sort-numeric-ascending</v-icon>
                  <span>{{ form.order }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Detail Dialog -->
    <v-dialog v-if="currentForm" v-model="formDetailDialog" max-width="1280px">
      <v-card>
        <v-card-title class="font-weight-bold"
          >{{ currentForm.studentName }}
        </v-card-title>

        <v-card-subtitle class="ma-4 text-center">
          {{ currentForm.studentCode }} - {{ currentForm.email }} -
          {{ currentForm.ipAddress }}
        </v-card-subtitle>

        <v-row align="center" justify-md="center">
          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-progress-question</v-icon>
              <div class="mr-1">Số câu đã làm:</div>
              <div class="font-weight-bold">
                {{ currentForm.status.currentQuestion }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-book</v-icon>
              <div class="mr-1">Số đề:</div>
              <div class="font-weight-bold">
                {{ currentForm.exam.code }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-sort-numeric-ascending</v-icon>
              <div class="mr-1">Số thứ tự:</div>
              <div class="font-weight-bold">
                {{ currentForm.order }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-home</v-icon>
              <div class="mr-1">Mã lớp:</div>
              <div class="font-weight-bold">
                {{ currentForm.classId }}
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row align="center" justify="center">
              <v-icon class="mr-1">mdi-account-group</v-icon>
              <div class="mr-1">Nhóm học:</div>
              <div class="font-weight-bold">
                {{ currentForm.studyGroupName }}
              </div>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn small color="error" @click="deleteSession(false)">
            <v-icon left>mdi-signature</v-icon>
            Xóa session
          </v-btn>
          <v-btn
            :disabled="currentForm.isStopped"
            small
            color="error"
            @click="deleteSession(true)"
          >
            <v-icon left>mdi-close</v-icon>
            {{ currentForm.isStopped ? "Đã ngừng thi" : "Ngừng thi" }}
          </v-btn>
          <v-btn small color="blue" @click="showFormDialog">
            <v-icon left>mdi-eye</v-icon>
            Xem bài thi
          </v-btn>
        </v-row>

        <v-card-text class="pa-4">
          <v-img
            :src="loading ? imageLoading : `/api/forms/${currentForm.id}/image?u=${randomNumber}`"
            :lazy-src="imageLoading"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="resetConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="h5 font-weight-bold">
          {{
            stop ? "Cho thí sinh ngừng thi" : "Xóa phiên làm việc của thí sinh"
          }}
        </v-card-title>

        <v-card-text>
          <div>
            {{
              stop
                ? "Tác vụ này sẽ khiến thí sinh không thể tiếp tục làm bài thi. Nếu muốn cho sinh viên thi tiếp cần ấn nút xóa session"
                : "Tác vụ này sẽ cho phép sinh viên đăng nhập trên một thiết bị mới một lần"
            }}
          </div>

          <v-text-field
            v-model="confirmText"
            :label="
              `Nhập chữ ${stop ? 'stop' : 'delete'} để đồng
          ý`
            "
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetConfirmDialog = false"> Hủy</v-btn>
          <v-btn
            color="error"
            :disabled="confirmText !== (stop ? 'stop' : 'delete')"
            @click="performDeleteSession"
          >
            THỰC THI
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="formDialog" max-width="800px">
      <v-card class="mt-0 pt-1" v-if="currentForm" style="width:100%">
        <v-card-title class="py-2">
          <span style="float:left">Mã đề: {{ currentForm.exam.code }}</span>
          <span style="float:right">Số câu đúng: {{ countCorrectAns }}</span>
        </v-card-title>
        <v-card-text
          class="py-0"
          style="height: calc(100vh - 155px);overflow-y: auto"
        >
          <div v-for="answer in currentForm.answers" :key="answer.id">
            <h4 class="mb-2">
              Câu {{ answer.question.order }} - Level
              {{ answer.question.originalQuestion.level }}
            </h4>
            <img :src="answer.question.originalQuestion.content" alt="" />
            <div v-for="choice in answer.question.choices" :key="choice.id">
              <p
                :style="{
                  'font-weight': answer.choices
                    .map(item => item.id)
                    .includes(choice.id)
                    ? 'bold'
                    : 'unset',
                  fontSize: '14px',
                  color: choice.originalChoice.isCorrect ? 'green' : 'unset'
                }"
                class="my-2"
              >
                Phương án {{ choice.order }}:
                <span v-if="choice.originalChoice.type === 'text'">{{
                  choice.originalChoice.content
                }}</span>
              </p>
              <img
                :src="choice.originalChoice.image"
                v-if="choice.originalChoice.image"
                alt=""
              />
            </div>
            <v-divider class="my-2"></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import request from "@/utils/request";
import ServerPivotSelect from "./ServerPivotSelect.vue";
import { resetFormSession } from "@/api/examination";
import { debounce } from "@/utils/debounce";
import ImageLoading from "@/assets/img-loading.png";
import { getForm } from "@/api/exam";
export default {
  components: {
    ServerPivotSelect
  },

  data() {
    return {
      examinationSubjectId: undefined,

      currentForm: undefined,
      formDetailDialog: false,
      formDialog: false,
      search: "",
      page: 1,
      pageSize: 50,

      forms: [],
      formTotal: 0,

      selectedClassId: undefined,
      selectedStudyGroupName: undefined,
      selectedOrderBy: "form_statuses.current_question",

      classIds: [],
      studyGroupNames: [],

      loading: false,
      confirmText: "",
      resetConfirmDialog: false,

      stop: false,
      randomNumber: 0
    };
  },

  computed: {
    paginationLength() {
      return Math.max(1, Math.ceil(this.formTotal / this.pageSize));
    },

    imageLoading() {
      return ImageLoading;
    },
    countCorrectAns() {
      if (this.currentForm && this.formDialog)
        return this.currentForm.answers.filter(item => item.isCorrect).length;
      return 0;
    }
  },

  watch: {
    examinationSubjectId(id) {
      this.forms = [];
      this.selectedClassId = undefined;
      this.selectedStudyGroupName = undefined;

      if (!id) return;

      this.onFilterChanged();
    },

    search() {
      this.onQueryChanged();
    },

    page() {
      this.fetchData();
    },
    resetConfirmDialog(val) {
      if (val) this.confirmText = "";
    }
  },

  created() {
    this.init();
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    async init() {
      await this.initExamination();
      this.initIntervalFetch();
    },

    async initExamination() {
      if (!("id" in this.$route.query)) return;

      const res = await request({
        url: `/examinations/${this.$route.query.id}/examination-subjects`
      });

      if (res.data.examinationSubjects.length <= 0) return;

      this.examinationSubjectId = res.data.examinationSubjects[0].id;
    },

    initIntervalFetch() {
      this.fetchData();
      this.intervalHandler = setInterval(() => {
        this.fetchData();
      }, 1 * 60 * 1000);
    },

    destroy() {
      if (this.intervalHandler) {
        clearInterval(this.intervalHandler);
        this.intervalHandler = undefined;
      }
    },

    onFilterChanged() {
      this.page = 1;
      this.forms = [];
      this.formTotal = 0;
      this.fetchData();
    },

    onQueryChanged: debounce(function() {
      this.onFilterChanged();
    }, 500),

    async fetchData() {
      this.randomNumber = Math.random()
      if (!this.examinationSubjectId) return;

      try {
        this.loading = true;

        const params = {
          examinationSubjectId: this.examinationSubjectId,
          page: this.page,
          page_size: this.pageSize,
          classId: this.selectedClassId,
          studyGroupName: this.selectedStudyGroupName,
          orderBy: this.selectedOrderBy
        };

        if (this.search) {
          params["search"] = this.search.trim();
        }

        const res = await request({
          url: "/examinations/monitoring",
          params
        });

        this.forms = res.data.forms.data;

        this.formTotal = res.data.forms.meta.total;

        this.classIds = res.data.classIds;
        this.studyGroupNames = res.data.studyGroupNames;
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      } finally {
        this.loading = false;
      }
    },

    showFormDetail(form) {
      this.currentForm = form;
      this.formDetailDialog = true;
    },

    deleteSession(stop) {
      this.stop = stop;
      this.resetConfirmDialog = true;
    },

    async performDeleteSession() {
      await resetFormSession({ formId: this.currentForm.id, stop: this.stop });
      this.resetConfirmDialog = false;

      this.currentForm.isStopped = this.stop;

      this.fetchData();
    },
    async showFormDialog() {
      const { data } = await getForm(this.currentForm.id);

      for (const ans of data.answers) {
        const correctChoices = ans.question.choices
          .filter(c => c.originalChoice.isCorrect)
          .map(item => item.id)
          .sort();
        const choices = ans.choices.map(item => item.id).sort();

        ans.isCorrect =
          JSON.stringify(correctChoices) === JSON.stringify(choices);
      }
      this.currentForm.answers = data.answers;
      this.formDialog = true;
    }
  }
};
</script>

<style scoped>
.item-tag {
  position: absolute;
  top: 2px;
  left: 4px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>